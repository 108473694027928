<template>
  <div class="change-phone-info">
    <section-title show-asterisk>信息完善</section-title>

    <cell-input title="用户姓名" v-model="form.name" placeholder="请输入用户姓名"></cell-input>

    <cell-input title="联系方式" v-model="form.phone" placeholder="请输入手机号码" type="tel"></cell-input>

    <obtain-captcha v-if="enableCaptcha" :phone="form.phone" v-model="form.captcha" title="手机号验证"
                    placeholder="请输入验证码" @sent="getSentStatus"></obtain-captcha>

    <serial-number v-if="isNeedSNVerification" :disabled="form.isCorrectSerialNumber " title="序列号" placeholder="请输入序列号"
                   v-model="form.serialNumber"
                   @inquiry="inquirySerialName"></serial-number>

    <field-piker labelWidth="10em" label="手机目前状态" title="手机目前状态" v-model="form.phoneStatus" placeholder="请选择手机目前状态"
                 :columns="phoneStatusList" value-key="name"></field-piker>

    <field-piker labelWidth="12em" label="是否有第三方维修历史" title="是否有第三方维修历史" v-model="form.isRepaired" placeholder="请选择"
                 :columns="isRepairedList" value-key="name"></field-piker>

    <field-piker labelWidth="10em" label="ID锁能否正常退出" title="选择ID锁能否正常退出" v-model="form.idLock"
                 placeholder="请选择ID锁能否正常退出"
                 :columns="idLockList" value-key="name"></field-piker>

    <field-piker labelWidth="10em" label="资料备份确认方式" title="选择资料备份确认方式" v-model="form.backup" placeholder="请选择资料备份确认方式"
                 :columns="backupList" value-key="name">
      温馨提示:置换新机时，请确认您旧手机已经完成备份，为保证您的资料安全，我们将对您得设备抹掉所有内容，资料将无法找回。<span class="how-to-backup"
                                                                      @click="haoToBackup">如何备份?</span>
    </field-piker>

    <double-choice-button title="焕新方式" :class="{'no-bottom-line':form.changeMethod.id===1}" v-model="form.changeMethod"
                          :choices="changeMethodList" @change="changeMethodChange"
                          value-key-="id"></double-choice-button>

    <template v-if="form.changeMethod.id===2">
      <get-address title="详细地址" placeholder="请手动输入或点击获取" v-model="form.address"
                   @click.native="scrollIntoView('address')" id="address"></get-address>

      <cell-input title="门牌号" v-model="form.houseNumber" placeholder="请输入门牌号"
                  @click.native="scrollIntoView('houseNumber')" id="houseNumber"></cell-input>
    </template>

    <choose-store v-if="form.changeMethod.id===1" v-model="form.store" :gps="gps"></choose-store>

    <reservation-time title="预约时间" v-model="form.time"></reservation-time>

    <protocol v-model="form.isReadProtocol" class="protocol">
      <span>我已经阅读并接受</span><span class="red" @click.stop="readProtocol">《{{ this.$store.state.config.title }}》</span>
    </protocol>

    <my-button class="change-btn" :class="{disabled:!isDone}" @click="confirm">提交焕新</my-button>

    <van-popup v-model="isShowSuccessDialog">
      <div class="success-dialog">
        <img class="dialog-bg-img" src="@/assets/images/bg_yytj.png" alt="">
        <img class="dialog-success-icon" src="@/assets/images/icon_success.png" alt="">
        <img class="dialog-close-icon" src="@/assets/images/close_white.png" @click="isShowSuccessDialog=false" alt="">
        <div class="dialog-content">
          <div class="title">预约提交成功</div>
          <div class="sub-title">您已预约成功，请保持电话畅通</div>
          <div class="view-detail-btn" @click="seeDetails">查看详情</div>
        </div>
      </div>
    </van-popup>

    <van-dialog v-model="isShowImeiPopup" title="提示" theme="round-button" :show-cancel-button="false"
                :before-close="imeiConfirm">
      <input type="text" class="imei-input" v-model="imei" placeholder="请重新输入IMEI号">
    </van-dialog>
  </div>
</template>

<script>
import ChooseStore from "@/components/ChooseStore";
import {getGps} from "@/utils/getGps";
import {checkChA, checkSn, setOrderActivity} from "@/api";

export default {
  name: "index",
  components: {ChooseStore},
  data() {
    return {
      form: {
        name: '',
        phone: '',
        captcha: '',
        phoneStatus: '',
        isRepaired: '',
        changeMethod: '',
        houseNumber: '',
        address: '',
        store: {},
        serialNumber: '',
        idLock: '',
        backup: '',
        time: '',
        isReadProtocol: false,
        isCorrectSerialNumber: false,
      },
      order_id: '',
      phoneStatusList: [
        {name: '完好', id: 1},
        {name: '损坏', id: 2}
      ],
      isRepairedList: [
        {name: '否', id: 0},
        {name: '是', id: 1}
      ],
      changeMethodList: [
        {name: '快递上门', id: 2, disabled: false, text: '暂不支持快递上门方式'},
        {name: '到店焕新', id: 1, disabled: false, text: '暂不支持到店焕新方式'},
      ],
      idLockList: [
        {name: '能正常退', id: 1},
        {name: '无法正常退', id: 0},
      ],
      backupList: [
        {name: '不用备份', id: 0},
        {name: '自助备份', id: 1},
        {name: '到店备份', id: 2},
      ],
      isDone: false,
      isShowSuccessDialog: false,
      gps: null,
      serialNumberQuery: null,
      alreadySentCaptcha: false,
      imei: '',
      snType: 1,
      isShowImeiPopup: false,
    }
  },
  computed: {
    enableCaptcha() {
      let flag = this.$store.state.config.is_sms === 1
      if (!flag) {
        this.$delete(this.form, 'captcha')
      }
      return flag;
    },
    //是否需要序列号验证
    isNeedSNVerification() {
      if (this.$store.state.config) {
        return this.$store.state.config.is_serial_num
      }
      return 1
    }
  },
  watch: {
    form: {
      handler() {
        this.checkIfItSDone()
      },
      deep: true
    },
    'form.phone'() {
      this.alreadySentCaptcha = false
    },
    'form.serialNumber'() {
      this.form.isCorrectSerialNumber = !this.isNeedSNVerification
    }
  },
  mounted() {
    let changeMethod
    switch (this.$store.state.config.pickup_mode) {
      case 0:
        this.changeMethodList[0].disabled = false
        this.changeMethodList[1].disabled = false
        changeMethod = ''
        break
      case 1:
        this.changeMethodList[0].disabled = true
        this.changeMethodList[1].disabled = false
        changeMethod = this.changeMethodList[1]
        this.$set(this.form, 'address', {})
        this.$delete(this.form, 'houseNumber')
        this.$delete(this.form, 'address')

        break
      case 2:
        this.changeMethodList[0].disabled = false
        this.changeMethodList[1].disabled = true
        changeMethod = this.changeMethodList[0]

        this.$set(this.form, 'houseNumber', '')
        this.$set(this.form, 'address', '')
        this.$delete(this.form, 'store')
        break
    }

    getGps().then(gps => {
      this.gps = gps
    }).finally(() => {
      this.form.changeMethod = changeMethod
    })
  },
  methods: {
    readProtocol() {
      this.$myDialog({
        type: 'text',
        message: this.$store.state.config.agreement,
        beforeClose:(action, done)=>{
          this.form.isReadProtocol=true
          done()
        }
      })
    },
    checkIfItSDone() {
      let unDone = ''
      for (const key in this.form) {
        let value = this.form[key]

        if (typeof value === 'string' && !value.trim()) {
          unDone = key;
          if (!this.isNeedSNVerification &&key==='serialNumber'&&!value) {
            unDone=''
          }
          if (unDone) {
            break
          }
        }
        if (typeof value === 'object' && JSON.stringify(value) === '{}' || JSON.stringify(value) === '[]') {
          unDone = key
          break
        }
        if (typeof value === 'boolean') {
          if (key === 'isReadProtocol' && !value) {
            unDone = key
            break
          }
          if (key === 'isCorrectSerialNumber'&&!value&&this.isNeedSNVerification) {
            unDone = key
            break
          }
        }
      }
      this.isDone = !unDone;
      console.log(`%c${unDone}`, 'color:red')
      return unDone
    },
    changeMethodChange(value) {
      let handler = () => {
        if (value.id === 2) {
          this.$set(this.form, 'houseNumber', sessionStorage.getItem('houseNumber') || '')
          this.$set(this.form, 'address', sessionStorage.getItem('address') || '')

          sessionStorage.setItem('store', JSON.stringify(this.form.store || {}))
          this.$delete(this.form, 'store')
        } else if (value.id === 1) {


          this.$set(this.form, 'store', sessionStorage.getItem('store') && JSON.parse(sessionStorage.getItem('store')) || '')

          sessionStorage.setItem('houseNumber', this.form.houseNumber || '')
          sessionStorage.setItem('address', this.form.address || '')
          this.$delete(this.form, 'houseNumber')
          this.$delete(this.form, 'address')
        }
      }
      if (!this.gps) {
        getGps(false).then(gps => {
          this.gps = gps
        }).finally(() => {
          handler()
        });
      } else {
        handler()
      }

    },
    checkIsCHN(type) {
      return checkChA({
        sn: this.form.serialNumber,
        imei: this.imei,
        type,
        phone: this.form.phone,
        code: this.form.captcha,
      })
    },
    checkSn() {
      let phoneInfo = this.$store.state.phoneInfo;
      checkSn({
        sn: this.form.serialNumber,
        old_model: phoneInfo.oldModel,
        old_colour: phoneInfo.oldColor,
        old_ram: phoneInfo.oldRAM,
        new_model: phoneInfo.newModel,
        new_colour: phoneInfo.newColor,
        new_ram: phoneInfo.newRAM,
        pass_id: this.$store.state.config.pass_id
      }).then((res) => {
        let data = res.data || {}
        if (res.code === 1) {
          console.log(`%c this.isNeedSNVerification`,'color:red',this.isNeedSNVerification)
          if (this.isNeedSNVerification) {
            this.form.isCorrectSerialNumber = true;
          }
          this.$toast(res && res.msg || '验证成功')
        } else if (res.code === 2) {

          let message = `
            <div style="text-align:left">查询到该序列号与所选旧机机型、存储、颜色不一致，继续提交将会替换原旧机机型。</div>
            <br>
            <div style="background: #FFEDEE;border-radius: 5px;padding: 10px 20px;text-align:left">
            <div style="font-weight: bold;margin-bottom: 10px">此序列号对应机型数据为：</div>
            <div style="color: #999;padding-left: 10px">
             <p>机型：<span style="color: #333;">${data.old_model}</span></p>
             <p>存储：<span style="color: #333;">${data.old_ram}</span></p>
             <p>颜色：<span style="color: #333;">${data.old_colour}</span></p>
             <p>旧机价格：<span style="color: #333;">${data.old_price}</span></p>
             <p>焕新价格：<span style="color: #333;">${data.money}</span></p>
             <p>旧机抵扣金额：<span style="color: #333;">${data.deduct_price}</span></p>
             <p>迭代补差金额：<span style="color: #333;">${data.diff_money}</span></p>
             </div>
            </div>`
          this.$myDialog({
            title: '是否确定提交？',
            message,
            type: 'text',
            isShowCancel: true,
            beforeClose: (action, done) => {
              if (action === 'confirm') {
                let phoneInfo = this.$store.state.phoneInfo
                phoneInfo.oldModel = data.old_model
                phoneInfo.oldColor = data.old_colour
                phoneInfo.oldRAM = data.old_ram
                phoneInfo.oldMoney = data.old_price
                phoneInfo.money = data.money
                phoneInfo.deduct_price = data.deduct_price
                phoneInfo.diff_money = data.diff_money
                this.$store.commit('setPhoneInfo', phoneInfo)
                if (this.isNeedSNVerification) {
                  this.form.isCorrectSerialNumber = true;
                }

                done()
              } else {
                if (this.isNeedSNVerification) {
                  this.form.isCorrectSerialNumber = false;
                }
                done()
              }
            }
          })
        }
      }).catch(err => {
        if (this.isNeedSNVerification) {
          this.form.isCorrectSerialNumber = false;
        }
        this.$toast(err && err.msg || '验证失败')
        console.error(err)
      })
    },
    inquirySerialName() {
      if (!this.alreadySentCaptcha) {
        this.$toast('请获取验证码验证手机号');
        return;
      }
      if (!this.form.serialNumber) {
        this.$toast('请输入旧机序列号');
        return;
      }

      this.checkIsCHN(1).then(res => {
        if (res.code === 1) {
          this.checkSn()
          this.serialNumberQuery = res.query
        }
        if (res.code === 2) {
          this.$toast(res.msg || '请切换imei验证', {
            duration: 2000
          });
          setTimeout(() => {
            this.isShowImeiPopup = true
          }, 2000)
        }
      }).catch(err => {
        console.log(err)
        this.$toast(err.msg || '验证失败');
      })

    },
    imeiConfirm(action, done) {
      this.checkIsCHN(2).then((res) => {
        done()
        this.checkSn()
        this.serialNumberQuery = res.query
      }).catch(err => {
        done()
        console.log(err)
        this.$toast(err.msg || '验证失败');
      })
    },
    scrollIntoView(name) {
      setTimeout(() => {
        let input = document.getElementById(name)
        if (input) {
          input.scrollIntoView(true);
        }
      }, 500)
    },
    confirm() {
      if (!this.isDone) {
        let errors = {
          name: '请填写用户姓名',
          phone: '请填写手机号码',
          captcha: '请输入验证码',
          serialNumber: '请填写旧手机序列号',
          phoneStatus: '请选择手机目前状态',
          isRepaired: '请选择旧手机是否有第三方维修历史',
          changeMethod: '请选择焕机方式',
          houseNumber: '请选择门牌号',
          address: '请选择详细地址',
          time: '请选择预约时间',
          isReadProtocol: '请阅读并接受协议',
          isCorrectSerialNumber: '请验证旧手机序列号',
          backup: '请选择资料备份确认方式',
          idLock: '请选择ID锁能否正常退出',
        }
        this.$toast(errors[this.checkIfItSDone(this.checkIfItSDone())])
        console.log(`%c this.checkIfItSDone()`, 'color:red;font-size:20px', this.checkIfItSDone())
        return
      }

      if (!/^1[3-9]\d{9}$/.test(this.form.phone)) {
        this.$toast('请输入正确手机号码');
        return
      }

      sessionStorage.clear()

      let {phoneInfo, config} = this.$store.state
      let form = this.form

      let params = {
        pass_id: config.pass_id,
        name: form.name,
        phone: form.phone,
        sn: form.serialNumber,
        old_model: phoneInfo.oldModel,
        old_ram: phoneInfo.oldRAM,
        old_colour: phoneInfo.oldColor,
        old_price: phoneInfo.oldMoney,
        new_model: phoneInfo.newModel,
        new_ram: phoneInfo.newRAM,
        new_colour: phoneInfo.newColor,
        new_price: phoneInfo.newMoney,
        deduct_price: phoneInfo.deduct_price,
        money: phoneInfo.money,
        diff_money: phoneInfo.diff_money,
        phone_status: form.phoneStatus.id,
        is_repair: form.isRepaired.id,
        new_way: form.changeMethod.id,
        make_date: form.time,
        address: form.address || '',
        house_num: form.houseNumber || '',
        store_id: form.store && form.store.store_id || '',
        code: form.captcha,
        query: this.serialNumberQuery,
        backup: form.backup.id,
        is_idout: form.idLock.id,
      }
      setOrderActivity(params).then((res) => {
        this.isShowSuccessDialog = true;
        this.order_id = res.data.order_id || ''
      }).catch(err => {
        this.$toast(err && err.msg || '预约失败')
        console.error(err)
      })

    },
    seeDetails() {
      this.$router.push({
        path: '/orderDetail',
        query: {
          order_id: this.order_id
        }
      })
    },
    haoToBackup() {
      this.$imagePreview({
        images: [
          'https://mapi.cdydkj.cn/public/img/p0001.png',
        ],
        maxZoom: 5
      })
    },
    getSentStatus() {
      this.alreadySentCaptcha = true
    },

  }
}
</script>

<style scoped lang="less">
.change-phone-info {
  width: 100vw;
  min-height: 100vh;
  background: #F6F6F6;
  padding-bottom: 150px;
}

.protocol {
  span {
    color: #999;
  }

  .red {
    color: @base-color;
  }
}

.change-btn {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100vw - 60px);
}

.protocol-wrapper {
  padding: 20px;
  font-size: 28px;
  color: #999;

  p {
    text-align: justify;
  }
}


::v-deep .change-btn.disabled button {
  opacity: 1;
  border-color: lighten(@base-color, 20%);
  background: lighten(@base-color, 20%);
}

::v-deep .no-bottom-line::after {
  display: none;
}

::v-deep .van-popup--center {
  border-radius: 20px;
  overflow: hidden;
}

.success-dialog {
  position: relative;
}

.dialog-bg-img {
  width: calc(100vw - 150px);
  height: 730px;
}

.dialog-success-icon {
  width: 162px;
  height: 162px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.dialog-close-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.dialog-content {
  position: absolute;
  top: 380px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 40px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 36px;
  }

  .sub-title {
    font-size: 26px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 100px;
  }

  .view-detail-btn {
    width: 320px;
    height: 80px;
    background: linear-gradient(90deg, #FF4040 0%, #FF5A5B 100%);
    box-shadow: 0 6px 15px 1px rgba(255, 89, 89, 0.27);
    border-radius: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 80px;
    text-align: center;
    letter-spacing: 5px;
  }
}

.how-to-backup {
  font-weight: bold;
  text-decoration: underline;
}

.imei-input {
  width: 500px;
  height: 80px;
  background: #F6F6F6;
  border: 1px solid #EEEEEE;
  opacity: 0.98;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 50px;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
}
</style>
