<template>
  <div class="choose-store">
    <div class="content" v-if="currentStore">
      <div class="store-name">{{ currentStore.store_name }}</div>
      <div class="store-distance" v-if="currentStore.distance">距离{{ currentStore.distance }}</div>
      <div class="address">{{ currentStore.province_name }}{{ currentStore.city_name }}{{
          currentStore.district_name
        }}{{ currentStore.address }}
      </div>
      <div class="choose-store-btn" @click="toggleStore">切换门店</div>
    </div>

    <div class="store-empty" v-if="!currentStore">
      <div style="font-size: 14px;color: #999">暂无门店列表</div>
      <div class="manual-choose" @click="manualChoose" style="margin-left: 20px">手动选择</div>
    </div>

    <van-popup v-model="isShowPopup" position="bottom" style="min-height: 70vh">
      <div class="popup-header">
        <span class="title">{{ label }}</span>
        <img class="cancel" @click="isShowPopup=false" src="@/assets/images/icon_gb_.png" alt="">

      </div>
      <div v-if="gps&&!isManual" class="gps-address">
        <img class="address-icon" src="@/assets/images/icon_dwx.png" alt="">
        <div class="gps-address-text">
          <div class="gps-address-text-wrapper">{{ gps.address }}</div>
        </div>
        <div class="manual-choose" @click="manualChoose">手动选择</div>
      </div>

      <div v-else class="address-select">
        <img class="address-icon" src="@/assets/images/icon_dwx.png" alt="">

        <div class="address-select-item province" @click="addressTypeChange(0)">
          <div
              class="address-text">{{ region[0] ? region[0].name : '省份' }}
          </div>
          <div class="options" :class="{current:currentAddressType===0}">
            <div class="option" :class="{active:region[0]&&region[0].id===item.id}"
                 v-for="item in addressList[0]" :key="item.id" @click="addressSelect(0,item)">{{ item.name }}
            </div>
          </div>
          <img src="@/assets/images/icon-fh.png" class="address-arrow" :class="{down:currentAddressType===0}"
               alt=""></div>

        <div class="address-select-item city" @click="addressTypeChange(1)">
          <div
              class="address-text">{{ region[1] ? region[1].name : '城市' }}
          </div>
          <div class="options" :class="{current:currentAddressType===1}">
            <div class="option" :class="{active:region[1]&&region[1].id===item.id}"
                 v-for="item in addressList[1]" :key="item.id" @click="addressSelect(1,item)">{{ item.name }}
            </div>
            <div class="option" v-if="!addressList[1]">请选择省份</div>
          </div>
          <img src="@/assets/images/icon-fh.png" class="address-arrow" :class="{down:currentAddressType===1}"
               alt=""></div>

        <div class="address-select-item district" @click="addressTypeChange(2)">
          <div
              class="address-text">{{ region[2] ? region[2].name : '区域' }}
          </div>
          <div class="options" :class="{current:currentAddressType===2}">
            <div class="option" :class="{active:region[2]&&region[2].id===item.id}"
                 v-for="item in addressList[2]" :key="item.id" @click="addressSelect(2,item)">{{ item.name }}
            </div>
            <div class="option" v-if="!addressList[2]">请选择城市</div>
          </div>
          <img src="@/assets/images/icon-fh.png" class="address-arrow" :class="{down:currentAddressType===2}"
               alt=""></div>
        <div class="manual-choose" @click="automaticPositioning" v-if="gps">自动定位</div>
      </div>
      <div class="popup-content">
        <div class="popup-store" v-for="item in storeList" :key="item.store_id" @click="chooseStore(item)">
          <img class="popup-store-radio" v-if="currentStoreCopy&&(currentStoreCopy.store_id===item.store_id)"
               :src="require(`@/assets/images/icon_gx.png`)" alt="">
          <img class="popup-store-radio" v-else :src="require(`@/assets/images/icon_gx_wx.png`)" alt="">

          <div class="popup-store-info">
            <div class="popup-store-name">{{ item.store_name }}</div>
            <div class="popup-store-address">{{ item.province_name }}{{ item.city_name }}{{
                item.district_name
              }}{{ item.address }}
            </div>
          </div>
          <div class="popup-store-distance">距离{{ item.distance }}</div>
        </div>

        <div class="empty" v-if="!storeList.length">此地区暂无门店~</div>
      </div>
      <my-button class="confirm-btn" @click="chooseConfirm">确定</my-button>

    </van-popup>
  </div>
</template>

<script>
import {getRegion, getStores} from "@/api";

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  name: "ChooseStore",
  props: {
    value: {
      type: [String, Object],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择附近门店'
    },
    label: {
      type: String,
      default: '选择附近门店'
    },
    gps: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      currentStore: {},
      currentStoreCopy: {},
      addressList: [],
      region: [],
      storeList: [],
      isShowPopup: false,
      currentAddressType: null,
      isManual: false
    }
  },
  mounted() {
    if (!this.gps && !this.getStoreData('currentStore')) {
      this.isManual = true;
      this.setStoreData('isManual', true)
    } else {
      this.isManual = this.getStoreData('isManual')
    }

    this.currentStore = this.currentStoreCopy = this.getStoreData('currentStore');

    if (this.isManual) {
      if (this.getStoreData('region') && this.getStoreData('addressList') && this.getStoreData('storeList')) {
        this.region = this.getStoreData('region')
        this.addressList = this.getStoreData('addressList')
        this.storeList = this.getStoreData('storeList')
      } else {
        this.getRegion(0).then(list => {
          this.addressList[0] = list
        })
      }
    } else {
      if (this.gps) {
        this.getStores().then(() => {
          if (!this.storeList.length) {
            this.$toast('此地区暂无店铺，请手动输入位置查询')
            return
          }
          if (!this.currentStore) {
            this.currentStore = this.currentStoreCopy = this.storeList[0]
          }
          this.$emit('change', this.currentStore)

          this.setStoreData('gps', this.gps)
          this.setStoreData('storeList', this.storeList)
          this.setStoreData('currentStore', this.currentStore)
        })
      }
    }
    // if (!this.currentStore) {
    //   this.isShowPopup = true;
    // }

  },
  watch: {
    currentAddressType(value) {
      if (value !== null) {
        document.body.addEventListener('click', this.bodyClick, false)
      } else {
        document.body.removeEventListener('click', this.bodyClick, false)
      }
    }
  },
  methods: {
    getStores() {
      let city = this.region[1] && this.region[1].id || ''
      let district = this.region[2] && this.region[2].id || ''
      let lon = this.gps && this.gps.lng || ''
      let lat = this.gps && this.gps.lat || ''
      return getStores({
        pass_id: this.$store.state.config.pass_id,
        page: 1,
        limit: 10000,
        city,
        district,
        lon,
        lat
      }).then(res => {
        this.storeList = res.data || [];
      })
    },
    getRegion(pid) {
      return getRegion({pid}).then(res => {
        let data = res.data || {}
        let options = []
        for (let key in data) {
          options.push({
            name: data[key],
            id: key,
            children: []
          })
        }
        return options
      })
    },
    setStoreData(key, value) {
      let storeData = this.$store.state.storeData;
      storeData[key] = value
      this.$store.commit('setStoreData', storeData)
    },
    getStoreData(key) {
      return this.$store.state.storeData[key]
    },
    clearStoreData() {
      this.$store.commit('setStoreData', {
        isManual: false,
        currentStore: null
      })
    },
    toggleStore() {
      this.isShowPopup = true
      this.currentStoreCopy = JSON.parse(JSON.stringify(this.currentStore))
    },
    addressTypeChange(index) {
      this.currentAddressType = this.currentAddressType === index ? null : index
    },
    bodyClick(e) {
      if (e.target.className !== 'address-text' && e.target.className.indexOf('address-arrow') === -1) {
        this.currentAddressType = null;
        document.body.removeEventListener('click', this.bodyClick, false)
      }
    },
    addressSelect(index, item) {
      this.region[index] = item

      this.storeList = []

      if (index === 2) {
        this.getStores()
        return
      }
      if (index === 1) {
        this.getStores()
      }
      this.getRegion(item.id).then(options => {
        this.addressList[index + 1] = options

        if (index === 0) {
          this.region = [this.region[0]]
          this.currentAddressType = 1
          this.$delete(this.addressList, 2)
        }
        if (index === 1) {
          this.region = [this.region[0], this.region[1]]
          this.currentAddressType = 2
        }
      });
    },
    manualChoose() {
      if (!this.storeList.length) {
        this.getStores()
      }
      this.isShowPopup=true;
      this.isManual = true
      this.storeList = []

      this.getRegion(0).then(list => {
        this.addressList[0] = list
      });
      this.currentStoreCopy = null
      this.setStoreData('isManual', true)
      this.setStoreData('currentStore', null)
    },
    automaticPositioning() {
      this.isManual = false
      this.storeList = []
      this.region = []

      this.getStores().then(() => {
        if (this.storeList.length) {
          this.currentStoreCopy = this.storeList[0]
          this.setStoreData('currentStore', this.storeList[0])
        }
      })


      this.currentStoreCopy = null
      this.setStoreData('isManual', false)
      this.setStoreData('currentStore', null)

    },
    chooseStore(store) {
      this.currentStoreCopy = store
    },
    chooseConfirm() {
      this.currentStore = this.currentStoreCopy
      this.isShowPopup = false

      this.$emit('change', this.currentStore)

      this.setStoreData('currentStore', this.currentStore)

      if (!this.gps || this.isManual) {
        this.setStoreData('region', this.region);
        this.setStoreData('addressList', this.addressList)
        this.setStoreData('storeList', this.storeList)
      }

    }
  }
}
</script>

<style scoped lang="less">
.choose-store {
  width: 100%;
  background: #fff;
  padding: 0 40px 20px;
}

.content {
  width: calc(100vw - 77px);
  background: #F6F6F6;
  border-radius: 14px;
  padding: 24px 20px;
  position: relative;
}

.content:after {
  position: absolute;
  box-sizing: border-box;
  content: '';
  pointer-events: none;
  width: calc(100vw - 77px);
  bottom: -19px;
  left: 0;
  border-bottom: 1px solid @border-color;
  transform: scaleY(0.5);
}

.store-name {
  width: 450px;
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  margin-bottom: 20px;
}

.address {
  width: 450px;
  font-size: 24px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
  z-index: 99;
}

.store-distance {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 22px;
  font-weight: 400;
  color: @base-color;
}

.choose-store-btn {
  position: absolute;
  top: 65px;
  right: 20px;
  width: 120px;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid @base-color;
  border-radius: 24px;
  font-size: 24px;
  font-weight: 400;
  color: @base-color;
  text-align: center;
  line-height: 46px;
}

.placeholder {
  color: #999;
}

.popup-header {
  height: 120px;
  line-height: 120px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #eee;

  .cancel {
    font-size: 30px;
    font-weight: bold;
    color: #999999;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }

  .title {
    font-size: 34px;
    font-weight: bold;
    color: #333333;
  }
}

.address-select {
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  padding: 0 34px 0 70px;
  align-items: center;
  position: relative;
  z-index: 99;
}

.address-select-item {
  display: flex;
  align-items: center;
}

.address-icon {
  width: 28px;
  height: 34px;
  position: absolute;
  left: 34px;
  top: 50%;
  transform: translateY(calc(-50% - 3px));
}

.address-arrow {
  width: 24px;
  height: 24px;
  position: relative;
  //top: 3px;
  left: 10px;
  transform: rotate(-180deg);
  transition: all 0.2s;

  &.down {
    transform: rotate(0);
  }
}

.gps-address {
  color: #666666;
  font-size: 30px;
  font-weight: bold;

  display: flex;
  min-height: 80px;
  width: 100%;
  padding: 0 34px 0 70px;
  align-items: center;
  position: relative;
  z-index: 99;
  justify-content: space-between;

  .gps-address-text-wrapper {
    width: 500px;
    white-space: nowrap;
    overflow-x: auto;
  }

  .gps-address-text {
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }
}

.manual-choose {
  height: 46px;
  line-height: 46px;
  padding: 0 10px;
  background: @base-color;
  color: #fff;
  border-radius: 5px;
  font-weight: normal;
  font-size: 26px;
}

.address-text {
  font-size: 30px;
  font-weight: bold;
  color: #666666;
  max-width: 6em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 80px;
  line-height: 80px;
}

.address-select .options {
  display: flex;
  position: absolute;
  top: 80px;
  //left: 0;
  flex-direction: column;
  max-height: 0;
  overflow: auto;
  min-width: 205px;
  background: #FFFFFF;
  opacity: 0.94;
  border-radius: 20px;
  box-shadow: 0 0 15px fade(@base-color, 50%);
  transition: max-height 0.2s;
  padding: 0 20px;

  &.current {
    max-height: calc(70vh - 340px);
  }

  .option {
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 28px;
    font-weight: 500;
    color: #333333;

    &.active {
      color: @base-color;
    }
  }
}

.district .options {
  right: 34px;
}

.popup-content {
  max-height: calc(70vh - 340px);
  overflow-y: auto;
}

.popup-store {
  display: flex;
  width: calc(100vw - 60px);
  background: #F6F6F6;
  border-radius: 14px;
  margin-bottom: 20px;
  padding: 20px;
  margin-left: 30px;
  align-items: center;
  position: relative;

  &:active {
    background: #ececec;
  }

  .popup-store-info {
    width: 500px;

    .popup-store-name {
      margin-bottom: 15px;
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }

    .popup-store-address {
      font-size: 24px;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
    }
  }

  .popup-store-distance {
    position: absolute;
    right: 10px;
    top: 20px;
    margin-right: 20px;
    font-size: 22px;
    font-weight: 400;
    color: @base-color;
  }

  .popup-store-radio {
    width: 36px;
    height: 36px;
    margin-right: 20px;
  }
}

.confirm-btn {
  width: calc(100vw - 60px);
  margin-left: 30px;
  position: absolute;
  bottom: 20px;
}

.empty {
  width: 100%;
  text-align: center;
  font-size: 28px;
  color: #999;
  margin-top: 200px;
}

.store-empty {
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
